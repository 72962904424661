import { ComponentType, LabelProps, LayoutProps, TagProps } from '@sport1/types/web'
import { extendTagList } from '@/utils/urlTranslator/getOptimizedTagList'
import extendActionButtons from '@/utils/urlTranslator/extendActionButtons'

const getTagOptimizedLayout = async (
    layout: LayoutProps,
    teamTag?: LabelProps | TagProps
): Promise<LayoutProps> => {
    await Promise.all(
        layout.components.map(async component => {
            if (component.type === ComponentType.TAG_LIST) {
                await extendTagList(component.content)
            } else if (component.type === ComponentType.POPULAR_CATEGORY_TAGS) {
                component.popularCategories.forEach(async category =>
                    extendTagList(category.popularTags)
                )
            } else if (
                (component.type === ComponentType.TEASER_CARD ||
                    component.type === ComponentType.NEWS_FEED_TEASER_CARD ||
                    component.type === ComponentType.HORIZONTAL_CARD ||
                    component.type === ComponentType.TEAM_TEASER_CARD ||
                    component.type === ComponentType.MEDIATHEK_TEASER_CARD ||
                    component.type === ComponentType.HERO_TEASER_CARD ||
                    component.type === ComponentType.ICON_TEASER_CARD) &&
                component.tags
            ) {
                await extendTagList(component.tags)
                if (component.actionButtons) {
                    await extendActionButtons(component.actionButtons, teamTag)
                }
            } else if (component.type === ComponentType.BUNDESLIGA_HUB) {
                if (component.content && component.content[0]?.tags) {
                    await extendTagList(component.content[0].tags)
                }
                if (component.actionButtons) {
                    await extendActionButtons(component.actionButtons, teamTag)
                }
            } else if (
                (component.type === ComponentType.SEARCH_RESULT_LIST ||
                    component.type === ComponentType.POPULAR_TAG_LIST ||
                    component.type === ComponentType.WIDGET ||
                    component.type === ComponentType.TOP_TEASER_CARD ||
                    component.type === ComponentType.EVENT_TEASER_CARD ||
                    component.type === ComponentType.STANDINGS_CARD) &&
                component.tags?.length
            ) {
                await extendTagList(component.tags)
            } else if (
                component.type === ComponentType.RESULT_TEASER ||
                component.type === ComponentType.MATCH_ELEMENTS_CARD
            ) {
                if (component.tags) {
                    await extendTagList(component.tags)
                }
                await Promise.all(
                    component.content.map(async card => {
                        if (card.tags) {
                            await extendTagList(card.tags)
                        }
                    })
                )
            }
        })
    )
    return layout
}

export default getTagOptimizedLayout
