import {
    ComponentType,
    LabelProps,
    LayoutProps,
    TagListProps,
    TagParamsProps,
    TagProps,
} from '@sport1/types/web'
import { NextApiRequest, NextApiResponse } from 'next'
import { LivetickerPageProps } from '@/types/page'
import Config from '@/utils/Config'
import setAdStatusInContent from '@/utils/ads/setAdStatusInContent'
import indexCompontentTypes from '@/utils/content/indexCompontentTypes'
import S1Error from '@/utils/errors/S1Error'
import getJson from '@/utils/fetch/getJson'
import { SportDataTagUrls } from '@/utils/navigation/Standard/types/NavigationItem'
import { getSeoOptimizedLayout } from '@/utils/seo/SeoOptimizer'
import { getEntryTo } from '@/utils/urlTranslator'
import getOptimizedTag from '@/utils/urlTranslator/getOptimizedTag'
import getOptimizedTagList from '@/utils/urlTranslator/getOptimizedTagList'
import getTagOptimizedLayout from '@/utils/urlTranslator/getTagOptimizedLayout'
import fetchMatch, { rejectLivetickerMatch } from '@/utils/liveticker/fetchMatch'
import WebToAppLivetickerTabMap from '@/utils/liveticker/WebToAppTabMap'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

// TODO: This is not the correct place for all functions inside of this file. We need to refactor/restructure this or maybe the whole api in a seperate task.
const Default = async (req: NextApiRequest, res: NextApiResponse): Promise<void> => {
    const {
        query: { id },
    } = req

    getLayout(id as string)
        .then(data => res.status(200).send(data))
        .catch(error =>
            res.status(404).send({
                message: error.message,
                id,
            })
        )
}
export default Default

async function getTag(tagUrl: string): Promise<TagProps | LabelProps> {
    const tag = await getJson(tagUrl)
    return getOptimizedTag(tag)
}

type GetLivetickerBaseProps = {
    sportDataTagUrls: SportDataTagUrls
    params?: TagParamsProps[]
    sportId?: string
}
type GetLivetickerByMatchIdProps = GetLivetickerBaseProps & {
    matchId: string
    selectedTab?: string
}

export async function getLivetickerByMatchId({
    matchId,
    sportDataTagUrls,
    params,
    sportId: sportIdProp,
    selectedTab,
}: GetLivetickerByMatchIdProps): Promise<Omit<LivetickerPageProps, 'navigation'>> {
    let sportIdentifier
    let sportId = sportIdProp
    params?.forEach(param => {
        if (param.title === 'sportIdentifier') {
            sportIdentifier = param.value
        } else if (param.title === 'sportId') {
            sportId = param.value
        }
    })

    if (!sportIdentifier) {
        return Promise.reject(new Error(`Fetching liveticker layout failed! Match ID: ${matchId}`))
    }

    if (sportIdentifier === sportIdentifiers.soccer.name) {
        return getSoccerLiveticker({
            matchId,
            sportDataTagUrls,
            sportIdentifier,
            selectedTab: selectedTab || 'uebersicht',
        })
    }

    const tagUrl = sportId
        ? sportDataTagUrls.liveTickerSportTagUrl
              .replace('{sportIdentifier}', sportIdentifier)
              .replace('{sportId}', sportId)
              .replace('{matchId}', matchId)
        : sportDataTagUrls.liveTickerCompetitionTagUrl
              .replace('{sportIdentifier}', sportIdentifier)
              .replace('{matchId}', matchId)

    const tag = await getTag(tagUrl)
    const layout =
        sportIdentifier === sportIdentifiers.wintersport.name
            ? await getLivetickerLayout(tag, selectedTab || 'liveticker')
            : await getLayoutByTag(tag)

    return {
        layoutData: layout,
        livetickerMatch: { match: tag.contextInfo?.match },
        deeplink: sportId
            ? `sport1://live-ticker/${sportIdentifier}/sport/${sportId}/match/${matchId}`
            : `sport1://live-ticker/${sportIdentifier}/${matchId}`,
        livetickerTabs:
            tag.navigationItems && tag.navigationItems.length > 1 ? tag.navigationItems : undefined,
        sportIdentifier,
    }
}

export async function getSoccerLiveticker({
    matchId,
    sportDataTagUrls,
    sportIdentifier,
    selectedTab,
}: Required<Omit<GetLivetickerByMatchIdProps, 'params' | 'sportId'>> & {
    sportIdentifier: string
}): Promise<Omit<LivetickerPageProps, 'navigation'>> {
    const tagUrl = sportDataTagUrls.liveTickerMatchNavigationInfo
        .replace('{sportIdentifier}', sportIdentifier)
        .replace('{matchId}', matchId)

    const [tag, livetickerMatch] = await Promise.all([
        getTag(tagUrl),
        fetchMatch(sportIdentifier, matchId),
    ])

    if (!livetickerMatch.match) {
        return rejectLivetickerMatch(sportIdentifier, matchId)
    }

    const layout = await getLivetickerLayout(tag, selectedTab)

    return {
        layoutData: layout,
        livetickerMatch,
        deeplink: `sport1://live-ticker/${sportIdentifier}/${matchId}/tabValue/${WebToAppLivetickerTabMap.get(
            selectedTab
        )}`,
        livetickerTabs: tag.navigationItems,
        sportIdentifier,
    }
}

type GetLivetickerByCompetitionIdProps = GetLivetickerBaseProps & {
    competitionId: string
}

export async function getLivetickerByCompetitionId({
    competitionId,
    sportDataTagUrls,
    params,
    sportId: sportIdProp,
}: GetLivetickerByCompetitionIdProps): Promise<Omit<LivetickerPageProps, 'navigation'>> {
    let sportIdentifier
    let sportId = sportIdProp
    params?.forEach(param => {
        if (param.title === 'sportIdentifier') {
            sportIdentifier = param.value
        } else if (param.title === 'sportId') {
            sportId = param.value
        }
    })

    if (!sportIdentifier || !sportId) {
        return Promise.reject(
            new Error(
                `Fetching liveticker layout failed because of missing ${
                    sportIdentifier ? 'sportId' : 'sportIdentifier'
                }! competition ID: ${competitionId}`
            )
        )
    }

    const tagUrl = sportDataTagUrls.liveTickerSportEventTagUrl
        .replace('{sportIdentifier}', sportIdentifier)
        .replace('{sportId}', sportId)
        .replace('{competitionId}', competitionId)

    const tag = await getTag(tagUrl)
    const layout = await getLayoutByTag(tag)

    return {
        layoutData: layout,
        livetickerMatch: { match: tag.contextInfo?.match },
        deeplink: `sport1://live-ticker/${sportIdentifier}/sport/${sportId}/competition/${competitionId}`,
    }
}

export async function getLivetickerLayout(
    tag: TagProps | LabelProps,
    selectedTab: string
): Promise<LayoutProps> {
    const tabSlug = await getEntryTo(`/daten{competitionSlug}/live-ticker/{matchId}/${selectedTab}`)
    let currentNavigationItem
    if (tabSlug) {
        currentNavigationItem = tag.navigationItems?.find(item => `/${item.value}`.match(tabSlug))
    }
    if (!currentNavigationItem) {
        return Promise.reject(
            new Error(
                `No layout url found found for the given slug: /daten{competitionSlug}/live-ticker/{matchId}/${selectedTab}`
            )
        )
    }
    return getLayout(currentNavigationItem.url)
}

export async function getWidgetByCompetitionId(
    competitionId: string,
    widget: 'gameplan' | 'standings',
    sportDataTagUrls: SportDataTagUrls,
    params?: TagParamsProps[]
): Promise<LayoutProps> {
    let sportIdentifier
    params?.forEach(param => {
        if (param.title === 'sportIdentifier') {
            sportIdentifier = param.value
        }
    })

    if (!sportIdentifier) {
        return Promise.reject(
            new S1Error(501, `Fetching ${widget} layout failed! competition ID: ${competitionId}`)
        )
    }

    const sportDataTagUrl =
        widget === 'standings' ? sportDataTagUrls.standingsTagUrl : sportDataTagUrls.gameplanTagUrl

    const tagUrl = sportDataTagUrl
        .replace('{sportIdentifier}', sportIdentifier)
        .replace('{competitionId}', competitionId)

    const tag = await getTag(tagUrl)

    return getLayoutByTag(tag)
}

export async function getCalendarBySportId(
    sportId: string,
    sportDataTagUrls: SportDataTagUrls,
    params?: TagParamsProps[]
): Promise<LayoutProps> {
    let sportIdentifier
    params?.forEach(param => {
        if (param.title === 'sportIdentifier') {
            sportIdentifier = param.value
        }
    })

    if (!sportIdentifier) {
        return Promise.reject(
            new S1Error(501, `Fetching Calendar layout failed! sport ID: ${sportId}`)
        )
    }

    const tagUrl = sportDataTagUrls.eventCalendarSportTagUrl
        .replace('{sportIdentifier}', sportIdentifier)
        .replace('{sportId}', sportId)

    const tag = await getTag(tagUrl)

    return getLayoutByTag(tag)
}

export async function getLayout(
    url: string,
    page?: number,
    teamTag?: LabelProps | TagProps
): Promise<LayoutProps> {
    const layoutUrl = `${url}${page ? `/page/${page}` : ''}`
    const layout = (await getJson(layoutUrl)) as LayoutProps

    const seoOptimizedLayout = await getSeoOptimizedLayout(layout)

    const tagOptimizedLayout = setAdStatusInContent({
        adsActive: Config.ADS_ACTIVE,
        content: await getTagOptimizedLayout(seoOptimizedLayout, teamTag),
    }) as LayoutProps

    const indexedCompontentsLayout = indexCompontentTypes(tagOptimizedLayout)

    return {
        ...indexedCompontentsLayout,
        meta: {
            ...indexedCompontentsLayout.meta,
        },
    }
}

export async function getLayoutBySlug(slug: string, page?: number): Promise<LayoutProps> {
    const tag = await getTagBySlug(slug)
    return getLayoutByTag(tag, page)
}

export async function getLayoutByTag(
    tag: TagProps | LabelProps,
    page?: number
): Promise<LayoutProps> {
    if (!tag.navigationItems?.length) {
        return Promise.reject(
            new S1Error(404, `Missing Navigation Items in Tag - ID: ${tag.contextId}`)
        )
    }
    return getLayout(tag.navigationItems[0].url, page)
}

export async function getLayoutByModeAndSlug(
    slug: string,
    layoutMode: 'VIDEO' | 'ARTICLE' | 'GALLERY' | 'HIGHLIGHTS',
    page?: number
): Promise<LayoutProps> {
    const tagUrl = await getEntryTo(slug)
    const tag = await getTag(`${Config.CMS_API}${tagUrl}/layoutMode/${layoutMode}/platform/web`)
    if (!tag.navigationItems?.length) {
        return Promise.reject(
            new S1Error(404, `Missing Navigation Items in Tag - ID: ${tag.contextId}`)
        )
    }
    return getLayout(tag.navigationItems[0].url, page)
}

export async function getPaginatedLayout(
    url: string,
    tag?: TagProps | LabelProps,
    page = 0
): Promise<LayoutProps> {
    if (tag?.contextType === 'TAG' && !url.includes(`/tag/${tag?.contextId}/`)) {
        url = `${url}/contentTag/${tag?.contextId}`
    }
    return getLayout(`${url}/page/${page}`)
}

export async function getTagBySlug(slug: string): Promise<TagProps | LabelProps> {
    const tagUrl = await getEntryTo(slug)
    return getTag(`${Config.CMS_API}${tagUrl}/platform/web`)
}

export async function getCompetitionsByTag(
    tagId: string
): Promise<(TagProps | LabelProps)[] | undefined> {
    const url = `${Config.CMS_API}/v2/de/cms/content/fragment/TAG_LIST/contentTag/${tagId}/categories/COMPETITION,SUBTOPIC`
    const layout = (await getJson(url)) as LayoutProps
    const tagList = layout.components.find(
        comp => comp.type === ComponentType.TAG_LIST
    ) as TagListProps

    if (tagList?.content?.length > 0) {
        await getOptimizedTagList(tagList.content)
        return tagList.content
    }

    return undefined
}
